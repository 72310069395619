// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AssessmentEnd.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator/translator';
import { HEALTH_ASSESSMENT_IDS } from 'utils/configuration/const/assessment-types';
import {
  getIsExternalSystemIntegrationSupported,
  replaceCoachHubTarget,
} from 'features/+coachHub/utils/localStorage';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: AssessmentEnd
const AssessmentEnd = (props) => {
  // PROPS
  const {
    assessmentType,
    finishable,
    handleFinish,
    errorEnd,
    managerControlled,
    customContent,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  const externalSystemSupported = getIsExternalSystemIntegrationSupported();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleViewResults = () => {
    if (managerControlled) {
      handleFinish?.(() => history.push('/'));
      return;
    }

    if (externalSystemSupported) {
      replaceCoachHubTarget('profile', '/my-profile');
    } else {
      const targetPage = HEALTH_ASSESSMENT_IDS.includes(assessmentType) ? 'well-being' : 'strength-profile';
      handleFinish?.(() => history.push(`/my-profile/${targetPage}?route=${assessmentType}`));
    }
  };

  // RENDER: AssessmentEnd
  return (
    <div
      className={classNames(styles.assessmentEnd)}
      data-test='AssessmentEnd'
    >
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>

          <div className={styles.sTitle}>
            { translate(customContent?.self_completed_success_title ?? 'assessment_success') }
          </div>

          <div className={styles.buttons}>
            <Button
              size='M'
              looks='secondary'
              disabled={!finishable}
              onClick={handleViewResults}
            >
              { translate(managerControlled
                ? 'close_lbl'
                : 'assessment_view_results') }
            </Button>
          </div>
        </>
      ) }
    </div>
  );
};

export default AssessmentEnd;
