// eslint-disable-next-line import/no-cycle
import { translate } from 'utils/translator/translator';
import { REGISTRATION_STATUSES } from 'utils/configuration/const/registration-statuses';
import { PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { setDecimalSeparator } from './userpreference';
import { timestampToDate } from './dateTools';
import { CAREER_LEVELS_BY_ID } from './configuration/const/career-level';
import { CURRENCY_IDS, CURRENCY_SYMBOLS } from './configuration/const/recruiting';

const DEFAULT_ASSESSMENTS_COUNT = 6;

const PERSONALITY_SCORE_KEY = 1;
const PREFERENCES_SCORE_KEY = 3;
const COMPETENCIES_SCORE_KEY = 4;

export const getMappedEmployees = (employees) => employees.map((employee) => {
  const { user } = employee;
  const id = user.id || employee.id;

  let statusPercentage = 0;
  let statusLabel = 'table_status_invited';

  const name = (!user.firstName || !user.lastName)
    ? (employee.name || user.mail)
    : [ user.firstName, user.lastName ].join(' ');

  const userGroup = employee.role
    ? (translate(`platform_roles_${employee.role.split('_CUSTOM')[0].toLowerCase()}`) || employee.role.toLowerCase())
    : '';

  if (user.profileCompletion && user.profileCompletion.completed > 0) {
    statusPercentage = Math.round(
      (user.profileCompletion.completed * 100)
      / (user.profileCompletion.available || DEFAULT_ASSESSMENTS_COUNT),
    );
    statusLabel = statusPercentage === 100
      ? 'table_status_assessments_completed'
      : 'table_status_assessments';
  } else if (user.registrationStatus === REGISTRATION_STATUSES.REGISTERED) {
    statusPercentage = 10;
    statusLabel = 'table_status_registered';
  }

  let careerLevel;
  if (user.companyData && user.companyData.careerLevel) {
    const cl = CAREER_LEVELS_BY_ID[user.companyData.careerLevel];
    if (cl) {
      careerLevel = translate(cl.translationKey) || cl.translationFallback;
    }
  }

  const mappedUser = {
    id,
    firstName: user.firstName,
    lastName: user.lastName,
    // if employee's name is equal to any of user group names, instead of name email will be shown
    name: Object.values(PLATFORM_ROLES).includes(name) ? user.mail : name,
    email: user.mail,
    company: user.company,
    companyId: user.companyId,
    position: (user.companyData && user.companyData.profession) ? user.companyData.profession : '',
    careerLevel,
    since: (user.companyData && user.companyData.companyEntryDate) ? timestampToDate(user.companyData.companyEntryDate) : '',
    userGroup,
    lastActive: user.lastActive,
    status: {
      percentage: statusPercentage,
      label: statusLabel,
    },
    userPicture: user.userPicture,
    orgRoles: user.organizationalRoles,
    profileCompletion: user.profileCompletion,
    roles: user.roles,
    registrationStatus: user.registrationStatus,
    disabled: user.disabled,
    settings: user.settings,
  };

  // layer scores. Used on role staffing page.
  if (employee.layerScores) {
    mappedUser.personalityScore = setDecimalSeparator(
      employee.layerScores[PERSONALITY_SCORE_KEY],
    );
    mappedUser.preferencesScore = setDecimalSeparator(
      employee.layerScores[PREFERENCES_SCORE_KEY],
    );
    mappedUser.competenciesScore = setDecimalSeparator(
      employee.layerScores[COMPETENCIES_SCORE_KEY],
    );
    mappedUser.totalScore = setDecimalSeparator(employee.score);
  }

  return mappedUser;
});

export const getMappedCandidate = (candidate) => {
  const { profileData } = candidate;

  const name = (!candidate.firstName || !candidate.lastName)
    ? (candidate.name || candidate.mail || translate('anonymous_user_lbl'))
    : [ candidate.firstName, candidate.lastName ].join(' ');

  const profileCreated = !!candidate.firstName && !!candidate.lastName;
  const position = profileData?.currentPosition || (profileCreated ? 'Career starter' : '');
  let industryExperienceMapped = profileData?.industryExperience
    ? profileData.industryExperience.map((experienceItem) => {
      let res = experienceItem.industry_name;
      if (experienceItem.experience) {
        res += ` - ${translate(`${experienceItem.experience}_label`)}`;
      }
      return res;
    }) : [];
  if (industryExperienceMapped.length <= 1) {
    [ industryExperienceMapped ] = industryExperienceMapped;
  }

  const country = profileData?.country
    ? translate(`config_country_${profileData.country.toLowerCase()}`)
    : '';
  const residence = profileData && (
    (country && profileData.city)
      ? `${country}, ${profileData.city}`
      : (country || profileData.city)
  );

  let residenceWithNationality = residence;
  if (profileData && profileData.nationality
    && profileData.nationality.length > 0 && residence) {
    residenceWithNationality += ` / ${translate(`config_nationality_${profileData.nationality[0]}`)}`;
  }

  const positionAndJobFamily = profileData && (
    (position && profileData.currentJobFamily && profileData.currentJobFamily.name)
      ? `${position} / ${profileData.currentJobFamily.name}`
      : (position || (profileData.currentJobFamily
        && profileData.currentJobFamily.name && profileData.currentJobFamily.name))
  );

  let gender;
  if (profileData && profileData.gender) {
    gender = translate(`gender_${profileData.gender}`);
  }

  let education;
  if (profileData && profileData.education) {
    education = translate(profileData.education);
  }

  let jobRequirements = [];
  if (profileData && profileData.remotePreferred) {
    jobRequirements.push(translate('remote_job_possible') || 'Remote-Arbeit möglich');
  }
  if (profileData && profileData.desiredWeeklyHours) {
    jobRequirements.push(translate('desired_weekly_hours', [ '{{hours}}', profileData.desiredWeeklyHours ])
      || `Gewünschte Wochenarbeitszeit: ${profileData.desiredWeeklyHours} h`);
  }
  if (profileData && profileData.desiredSalary) {
    const currencySymbol = (profileData.currency && CURRENCY_SYMBOLS[profileData.currency])
      ? CURRENCY_SYMBOLS[profileData.currency]
      : CURRENCY_SYMBOLS[CURRENCY_IDS.EURO];

    jobRequirements.push(`${translate('desired_salary')}: ${profileData.desiredSalary} ${currencySymbol}`
      || `Gehaltswunsch p.a.: ${profileData.desiredSalary} ${currencySymbol}`);
  }

  if (profileData && profileData.additionalRequirements) {
    jobRequirements = [ ...jobRequirements, ...profileData.additionalRequirements ];
  }

  let motivation = [];
  if (profileData && profileData.motivation) {
    motivation = profileData.motivation.map((m) => {
      if (m === 'culture' || m === 'opportunities' || m === 'salary') {
        return translate(`cp_motivation_${m}_question`) || m;
      }
      return m;
    });
  }

  let age;
  if (profileData && profileData.yearOfBirth) {
    age = new Date().getFullYear() - profileData.yearOfBirth;
  }

  let careerLevel;
  if (profileData && profileData.careerLevel) {
    const careerLevelObj = CAREER_LEVELS_BY_ID[profileData.careerLevel];
    careerLevel = translate(`${careerLevelObj.translationKey}`) || careerLevelObj.translationFallback;
  }

  const desiredSalaryString = (profileData && profileData.desiredSalary)
    ? `${profileData.desiredSalary} ${profileData.currency || ''}` : '';

  return {
    ...candidate,
    name,
    position,
    profileCreated,
    profileData: {
      ...profileData,
      careerLevel,
      age,
      gender,
      education,
      residence,
      positionAndJobFamily,
      residenceWithNationality,
      industryExperienceMapped,
      jobRequirements,
      motivation,
      desiredSalaryString,
    },
  };
};

export const getFullName = (user) => {
  const name = [ user?.firstName, user?.lastName ].filter(Boolean).join(' ') || user?.name;
  const mail = user?.mail || user?.email;
  return name || mail || '';
};
