// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Subdimension.module.scss';

// OTHER COMPONENTS
import { IstIndicator } from 'ui/basic';


// COMPONENT: Subdimension
const Subdimension = ({
  id = '',
  title = '',
  description = '',
  score = 0,
  vulnerable = false,
  children,
  diff,
}) => (
  <div className={styles.subdimension} id={id}>
    <div className={styles.intro}>
      <div>
        <h3>{ title }</h3>
        { description }
      </div>
      <IstIndicator
        color={vulnerable ? 'red' : 'light'}
        score={score}
        diff={diff}
      />
    </div>
    { children }
  </div>
);


export default Subdimension;
