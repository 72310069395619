// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './ExternalAssessmentWrapper.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

// OTHER COMPONENTS
import { Button, Loading } from 'ui/basic';
import CustomAssessmentPage from 'features/+core/pages/Assessment/CustomAssessmentPage';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { COMPANY_ID } from 'utils/configuration';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import { getCompanySettings } from 'store/selectors/settings';
import { getConfigurationExtended } from 'store/actions/configuration';
import { getTokenNameForCurrentPage } from 'api';

// CONFIG & DATA
// const Config = {};


// COMPONENT: ExternalAssessmentWrapper
const ExternalAssessmentWrapper = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const translate = useTranslate();

  const { shareToken, customAssessmentId } = params;

  const companySettings = useSelector(getCompanySettings);

  const [ shareTokenInfo, setShareTokenInfo ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ reLoggedIn, setReLoggedIn ] = useState(false);
  const [ errorMessageBody, setErrorMessageBody ] = useState('');

  const userHasBqAccount = companySettings.openRegistrationDomains?.includes(shareTokenInfo?.peer?.split('@')?.[1]);

  const initLoading = useCallback(async () => {
    const tokenName = getTokenNameForCurrentPage();
    setLoading(true);

    try {
      const calls = [ apiNext.get(`/core/user/profile/share/${shareToken}`) ];
      if (!localStorage.getItem(tokenName)) {
        calls.push(apiNext.post('/core/user/login', {
          shareToken,
          company: COMPANY_ID,
        }));
      }

      const [ tokenInfoResponse, loginResponse ] = await Promise.all(calls);
      setShareTokenInfo(tokenInfoResponse);
      if (loginResponse) {
        localStorage.setItem(tokenName, loginResponse.token);
        dispatch(actions.getCompanySettings(COMPANY_ID));
      }
      dispatch(getConfigurationExtended());
    } catch (error) {
      localStorage.removeItem(tokenName);
      setErrorMessageBody(error.responseContent?.error?.errorMessage ?? error.message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [ dispatch, shareToken ]);

  useEffect(() => {
    initLoading();
  }, [ initLoading ]);


  // HANDLERS
  const handleErrorButtonClick = () => {
    if (userHasBqAccount) {
      history.push('/');
    } else {
      window.open('https://bluquist.com/', '_self');
    }
  };

  const handleError = useCallback((error = {}) => {
    localStorage.removeItem(getTokenNameForCurrentPage());
    if (reLoggedIn) {
      setErrorMessageBody(error.errorMessage || translate('assmnt_config_error'));
      return;
    }
    setReLoggedIn(true);
    initLoading();
  }, [ translate, initLoading, reLoggedIn ]);


  // RENDER: ExternalAssessmentWrapper
  if (loading) {
    return <Loading visible />;
  }

  if (errorMessageBody) {
    let title = 'generic_error_title';
    let body = errorMessageBody;

    if (errorMessageBody === 'Invalid share token') {
      title = 'unavailable_error_title';
      body = 'unavailable_error_body';
    }

    return (
      <div className={styles.externalAssessmentWrapper}>
        <div className={styles.gridContent}>
          <div className='bluTypeM'>
            { translate(title) }
          </div>
          <div className={classNames('bluTypeCopy', 'marginTopS')}>
            { translate(body) }
          </div>

          <Button
            size='L'
            onClick={handleErrorButtonClick}
          >
            { userHasBqAccount
              ? translate('share_profile_link_expired_btn')
              : translate('bq_learn_more') }
          </Button>
        </div>
      </div>
    );
  }

  return (
    <CustomAssessmentPage
      assessmentId={customAssessmentId}
      user={{
        id: shareTokenInfo?.peer,
        hasBqAccount: userHasBqAccount,
      }}
      shareToken={shareToken}
      peerFor={getFullName(shareTokenInfo?.forUser)}
      onError={(error = {}) => handleError(error)}
    />
  );
};

export default ExternalAssessmentWrapper;
