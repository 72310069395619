// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './IstIndicator.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENT: IstIndicator
const IstIndicator = (props) => {
  // PROPS
  const {
    size = 'M', // M (default), S small
    color = '',
    score,
    diff,
  } = props;

  const roundedDiff = Math.round(diff);
  // eslint-disable-next-line no-nested-ternary
  const diffSign = roundedDiff === 0 ? '+/-' : (roundedDiff > 0 ? '+' : '-');
  // eslint-disable-next-line no-nested-ternary
  const diffStyle = roundedDiff === 0 ? 'grey' : (roundedDiff > 0 ? 'blue' : 'red');

  // RENDER: IstIndicator
  return (
    <div className={styles.istIndicator}>
      <div className={classNames(
        styles.indicator,
        {
          [styles.small]: size === 'S',
          [styles.danger]: color === 'red',
          [styles.light]: color === 'light',
        },
      )}
      >
        <IconsSvg.Ist />
      </div>

      { Number.isFinite(score) && (
        <div className={styles.scoreWrapper}>
          <span>{ Math.round(score) }</span>
          <span className={styles.smallRatio}>{ ' / 100' }</span>
        </div>
      ) }

      { Number.isFinite(diff) && (
        <div className={classNames(styles.trend, styles[diffStyle])}>
          <span>{ `${diffSign} ${Math.abs(roundedDiff)}` }</span>
          <IconsSvg.Arrow />
        </div>
      ) }
    </div>
  );
};

export default IstIndicator;
