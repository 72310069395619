// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuestionFreeText.module.scss';

// OTHER COMPONENTS
import {
  Button, InfoCard, InputNext, ResizableTextArea,
} from 'ui/basic';
import { QuestionAiFeedback } from '../QuestionAiFeedback';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { useParams } from 'react-router';

let fetchTipsRetry = 1;

const Config = {
  maxFetchTipsRetry: 5,
};

// COMPONENT: QuestionFreeText
const QuestionFreeText = (props) => {
  // PROPS
  const {
    question,
    referenceQuestion,
    isAnswered = false,
    allowAnswerSkip = false,
    selectedValue,
    freeTextAiAssistanceEnabled = false,
    onChange,
    onAnswer,
    onBack,
    onForward,
    onHelp,
    onNavigationUpdate,
    clickBlock,
    localBlock,
    setLocalBlock,
  } = props;

  const aiAssistanceEnabled = Boolean(freeTextAiAssistanceEnabled && question.ai_assistance_enabled);
  const answerForReferenceIsChanged = Boolean(referenceQuestion?.answerIsChanged);

  // SPECIAL HOOKS
  const translate = useTranslate();
  const params = useParams();

  const [ value, setValue ] = useState('');
  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [ selectedValue ]);

  const [ tips, setTips ] = useState('');
  const [ tipsError, setTipsError ] = useState('');
  const [ tipsProcessing, setTipsProcessing ] = useState(false);
  const [ tipsExpanded, setTipsExpanded ] = useState(false);

  const fetchTip = useCallback(() => {
    if (question.tips && !answerForReferenceIsChanged) {
      setTips(question.tips);
      setTipsExpanded(false);
      return;
    }

    setTipsProcessing(true);
    setTipsError('');
    apiNext.get(`/core/assessments/${params.customAssessmentId}/0/answers/${question.id}/tip`)
    .then(({ tip }) => {
      question.tips = tip;
      if (referenceQuestion) {
        referenceQuestion.answerIsChanged = false;
      }

      setTips(tip);
      setTipsExpanded(true);
    })
    .catch((error = {}) => {
      if (error.response?.status === 404 && !isAnswered && fetchTipsRetry <= Config.maxFetchTipsRetry) {
        fetchTipsRetry += 1;
        fetchTip();
        return;
      }

      console.error(error.message);
      setTipsError(error.message);
    })
    .finally(() => {
      setTipsProcessing(false);
    });
  }, [
    params.customAssessmentId,
    question,
    answerForReferenceIsChanged,
    referenceQuestion,
    isAnswered,
  ]);

  useEffect(() => {
    if (!aiAssistanceEnabled || !question || tips || tipsProcessing || tipsError) {
      return;
    }

    fetchTip();
  }, [
    tips,
    question,
    tipsProcessing,
    tipsError,
    aiAssistanceEnabled,
    fetchTip,
  ]);

  const [ errorMessage, setErrorMessage ] = useState('');
  const validateAnswer = useCallback(() => {
    let errorMessageInternal = '';

    if (question.minAnswerLength && value.trim().length < question.minAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_min_length_error',
        [ '{{minLength}}', question.minAnswerLength ],
      );
    } else if (question.maxAnswerLength && value.trim().length > question.maxAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_max_length_error',
        [ '{{maxLength}}', question.maxAnswerLength ],
      );
    }

    return errorMessageInternal;
  }, [ translate, question, value ]);

  const handleChange = (valueInternal) => {
    setValue(valueInternal);
    onChange?.();
  };

  const [ animatePage1, setAnimatePage1 ] = useState(false);
  const [ animatePage2, setAnimatePage2 ] = useState(false);

  const [ page, setPage ] = useState(0); // initial free-text question page
  const handleSubmit = useCallback(() => {
    if (clickBlock || localBlock?.current) {
      return;
    }
    setLocalBlock();

    const error = validateAnswer();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage('');

      let valueInternal = value;
      if ((allowAnswerSkip || question.skipAnswer) && value === '') {
        valueInternal = null;
      }

      if (aiAssistanceEnabled && valueInternal
        // if free-text question answer or the answer reference question are changed
        && (!selectedValue || selectedValue !== valueInternal || answerForReferenceIsChanged)
      ) {
        setAnimatePage1(true);

        setTimeout(() => {
          setPage(1); // AI feedback page
          setAnimatePage2(true);

          question.hideNavigation = true;
          onNavigationUpdate?.();
        }, 300);
      } else {
        onAnswer?.(valueInternal);
      }
    }
  }, [
    question,
    value,
    selectedValue,
    validateAnswer,
    allowAnswerSkip,
    aiAssistanceEnabled,
    onAnswer,
    onNavigationUpdate,
    answerForReferenceIsChanged,
    clickBlock,
    localBlock,
    setLocalBlock,
  ]);

  const [ inputFocused, setInputFocused ] = useState(false);
  const handleKey = useCallback((event) => {
    const { key } = event;
    switch (key) {
      case 'ArrowUp': {
        if (!inputFocused && page === 0) { // not on an AI feedback page
          onBack?.();
        }
        break;
      }
      case 'ArrowDown': {
        if (!inputFocused && page === 0) {
          onForward?.();
        }
        break;
      }
      case 'Enter': {
        if (!inputFocused) {
          handleSubmit();
        }
        break;
      }
      default:
    }
  }, [
    page,
    inputFocused,
    onBack,
    onForward,
    handleSubmit,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [ handleKey ]);

  if (!question) {
    return null;
  }

  if (page === 1) {
    return (
      <div className={classNames({ [styles.page2Animation]: animatePage2 })}>
        <QuestionAiFeedback
          question={{
            id: question.id,
            question: question.question,
            ai_assistance_allow_reevaluation: question.ai_assistance_allow_reevaluation,
            value,
            maxAnswerLength: 1000,
            tips,
          }}
          onChange={() => onChange?.()}
          onSubmit={(valueInternal) => onAnswer?.(valueInternal)}
        />
      </div>
    );
  }
  // RENDER: QuestionFreeText
  return (
    <div className={classNames(styles.questionFreeText, { [styles.page1Animation]: animatePage1 })}>
      <div className={styles.gridContent}>
        { question.question && (
          <div className='bluTypeS'>{ question.question }</div>
        ) }

        { question.description && (
          <div className={styles.questionDescription}>
            { translate(question.description) }
          </div>
        ) }

        { aiAssistanceEnabled && (
          <div className={styles.infoCardWrapper}>
            <InfoCard
              looks='evo'
              content={tips || (tipsError && <span className='error'>{ tipsError }</span>)}
              loading={tipsProcessing}
              isExpanded={tipsExpanded}
            />
          </div>
        ) }

        <div className={styles.questionInput}>
          { !question.multiline && (
            <InputNext
              label={translate('your_answer_lbl')}
              value={value}
              clearValueOnConfirm={false}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={handleChange}
              onConfirm={handleSubmit}
            />
          ) }

          { question.multiline && (
            <ResizableTextArea
              label={translate('your_answer_lbl')}
              value={value}
              minRows={2}
              maxRows={5}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={handleChange}
              onConfirm={handleSubmit}
            />
          ) }
        </div>

        { (question.maxAnswerLength || errorMessage) && (
          <div className={styles.bottomText}>
            { errorMessage && (
              <span className={styles.error}>{ errorMessage }</span>
            ) }

            { question.maxAnswerLength && (
              <div className={styles.hint}>
                { `${value.length} / ${question.maxAnswerLength}` }
              </div>
            ) }
          </div>
        ) }

        { (allowAnswerSkip || question.skipAnswer) && (
          <div
            role='button'
            tabIndex={0}
            className={styles.skip}
            onClick={() => onAnswer?.(null)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onAnswer?.(null);
              }
            }}
          >
            { question.skip_option_label || translate('question_can_not_answer_label') }
          </div>
        ) }

        <Button
          size='M'
          onClick={handleSubmit}
        >
          { translate('wp_ass_selection_button_next') }
        </Button>

        { !question.hideHelp && (
          <Button
            size='S'
            looks='tertiary'
            onClick={() => onHelp?.()}
          >
            { translate('assessment_help_button') }
          </Button>
        ) }
      </div>
    </div>
  );
};

export default QuestionFreeText;
