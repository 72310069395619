// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './IstRangeDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';

// ASSETS
import { IconsSvg } from 'assets/icons';

// CONFIG & DATA
const Config = {
  scoreBarWidth: 3,
};


// COMPONENT: IstRangeDiagram
const IstRangeDiagram = (props) => {
  // PROPS
  const {
    label = '',
    score,
    prevScore,
    min = 0,
    max = 10,
    cutoff = 0,
    showDecimal = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const renderDiff = () => {
    if (![ score, prevScore ].every(Number.isFinite)) {
      return undefined;
    }

    const diff = score - prevScore;

    // eslint-disable-next-line no-nested-ternary
    const diffSign = diff === 0 ? '+/-' : (diff > 0 ? '+' : '-');
    // eslint-disable-next-line no-nested-ternary
    const diffStyle = diff === 0 ? 'grey' : (diff > 0 ? 'blue' : 'red');

    return (
      <div className={classNames(styles.trend, styles[diffStyle])}>
        (
        <span>
          { `${diffSign} ${setDecimalSeparator(Math.abs(diff), Number(showDecimal))}` }
        </span>
        <IconsSvg.Arrow />
        )
      </div>
    );
  };

  // RENDER: IstRangeDiagram
  return (
    <div className={styles.istRangeDiagram}>
      <svg>
        <rect
          width='100%'
          className={styles.container}
        />
        { cutoff > min && (
          <rect
            width={`${((cutoff - min) / (max - min)) * 100}%`}
            className={styles.range}
          />
        ) }
        <rect
          width={Config.scoreBarWidth}
          x={`${((score - min) / (max - min)) * 100}%`}
          // Subtract bar width from x coord to prevent overflow when score is 100%
          transform={`translate(${score === max ? -Config.scoreBarWidth : 0}, 0)`}
          className={classNames(
            styles.score,
            { [styles.danger]: cutoff && score > cutoff },
          )}
        />

        { prevScore && (
          <rect
            width={Config.scoreBarWidth}
            x={`${((prevScore - min) / (max - min)) * 100}%`}
            transform={`translate(${prevScore === max ? -Config.scoreBarWidth : 0}, 0)`}
            className={classNames(
              styles.prevScore,
              { [styles.danger]: cutoff && prevScore > cutoff },
            )}
          />
        ) }
      </svg>

      { label && (
        <div className={styles.label}>
          { label }
          <div className={styles.score}>
            { translate(
              'ist_result_range_diagram_ratio',
              [
                '{{score}}', setDecimalSeparator(score, Number(showDecimal)),
                '{{total}}', max,
              ],
            ) }

            { renderDiff() }
          </div>
        </div>
      ) }
    </div>
  );
};

export default IstRangeDiagram;
