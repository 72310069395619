// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './DetailedResults.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { IstRangeDiagram, IstIndicator } from 'ui/basic';
import { Subdimension } from './Subdimension';

// UTILS
import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  dimensions: {
    stressors_occ_personal: { max: 6, cutoff: null },
    stressors_occ_external: { max: 8, cutoff: null },
    stressors_fam_interpersonal: { max: 6, cutoff: null },
    stressors_personal_burdens: { max: 8, cutoff: null },
    stress_amplifier_perfectionism: { max: 10, cutoff: 6 },
    stress_amplifier_missing_dissociation: { max: 10, cutoff: 6 },
    stress_amplifier_self_expectation: { max: 10, cutoff: 6 },
    stress_amplifier_fears_concerns: { max: 10, cutoff: 6 },
    stress_amplifier_overload: { max: 10, cutoff: 6 },
    cbi_personal_burnout: { max: 24, cutoff: 16 },
    cbi_occupational_burnout: { max: 28, cutoff: 14 },
    eri_effort: {
      min: 1, max: 4, cutoff: 3.2, showDecimal: true,
    },
    eri_reward: { min: 1, max: 4, showDecimal: true },
    eri_esteem: { min: 1, max: 4, showDecimal: true },
    eri_security: { min: 1, max: 4, showDecimal: true },
    eri_promotion: { min: 1, max: 4, showDecimal: true },
    eri_overcommitment: {
      min: 1, max: 4, cutoff: 3, showDecimal: true,
    },
  },
};


// COMPONENT: DetailedResults
const DetailedResults = (props) => {
  // PROPS
  const { ist } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const symptoms = ist?.results?.filter((el) => el.parentDimension === 'symptoms' && el.result >= 1) || [];

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const getDimension = (key) => ist.results.find(({ id }) => id === key);

  const getDiff = (dimensionId) => {
    const currentResult = getDimension(dimensionId)?.result;
    const prevResult = ist.prevResults?.find(({ id }) => id === dimensionId)?.result;

    if (![ currentResult, prevResult ].every(Number.isFinite)) {
      return undefined;
    }

    return currentResult - prevResult;
  };

  const getBioMedResult = (key) => {
    const { result } = getDimension(key);
    if (result === 0) {
      return translate('feedback_no_lbl');
    }
    if (result === 1) {
      return translate('feedback_yes_lbl');
    }
    return '-';
  };

  // RENDER: Bars
  const renderBars = (dimension) => (
    <div className={styles.bars}>
      { Object.keys(Config.dimensions)
      .map((key) => ({
        ...ist.results.find(({ id }) => id === key),
        prevResult: ist.prevResults?.find(({ id }) => id === key)?.result,
      }))
      .filter((el) => el.parentDimension === dimension)
      .map((el) => (
        <IstRangeDiagram
          key={el.id}
          label={el.name}
          score={el.result}
          prevScore={el.prevResult}
          {...Config.dimensions[el.id]}
        />
      )) }
    </div>
  );

  const renderPhqResults = () => {
    const phqSubDimensions = [
      {
        id: 'phq_depressive_syndrome_major',
        result: getDimension('phq_depressive_syndrome_major').result,
      },
      {
        id: 'phq_depressive_syndrome_other',
        result: getDimension('phq_depressive_syndrome_other').result,
      },
      {
        id: 'phq_panic',
        result: getDimension('phq_panic').result,
      },
      {
        id: 'phq_anxiety',
        result: getDimension('phq_anxiety').result,
      },
      {
        id: 'phq_ptsd',
        result: getDimension('phq_ptsd').result,
      },
    ];

    const noDepressiveSyndromes = phqSubDimensions
    .every(({ result }) => result < 1);

    if (noDepressiveSyndromes) {
      return (
        <div className={styles.section}>
          <span>
            { translate('phq_depressive_syndrome_any') }
          </span>
        </div>
      );
    }

    const results = [];
    phqSubDimensions.forEach(({ id, result }) => {
      if (result >= 1) {
        results.push(
          <div key={id} className={styles.section}>
            { translate(id) }
          </div>,
        );
      }
    });

    if (!noDepressiveSyndromes) {
      results.push(
        <div className={styles.hint} key='no-dep'>
          { translate('phq_depressive_syndrome_hint') }
        </div>,
      );
    }

    return results;
  };

  const renderList = (key) => {
    const { result } = getDimension(key);

    // Special case: when only one option is selected, the option text is returned
    if (typeof result === 'string') {
      return <span>{ result }</span>;
    }

    if (!result?.length) {
      return <span>-</span>;
    }

    return (
      <ul>
        { result.map((el) => <li key={el}>{ el }</li>) }
      </ul>
    );
  };

  // RENDER: symptoms (grouped by result strength)
  const renderSymptoms = () => {
    if (!symptoms?.length) {
      return (
        <div className={styles.section}>
          <span>
            { translate('ist_result_subdimension_symptoms_indicated_empty') }
          </span>
        </div>
      );
    }

    const strengthGroups = {};
    symptoms.forEach((symptom) => {
      if (!(symptom.result in strengthGroups)) {
        strengthGroups[symptom.result] = [];
      }
      strengthGroups[symptom.result].push(symptom.name);
    });

    return Object.entries(strengthGroups)
    .sort(([ k1 ], [ k2 ]) => k2 - k1)
    .map(([ k, results ]) => (
      <div key={k} className={styles.section}>
        <span>
          { translate(`ist_result_subdimension_symptoms_indicated_${k}`) }
        </span>
        <ul>
          { results.map((el) => <li key={el}>{ translate(el) }</li>) }
        </ul>
      </div>
    ));
  };

  // RENDER: DetailedResults
  return (
    <div className={classNames(styles.detailedResults)}>
      <div>
        <h2>{ translate('ist_report_detailed_results_title') }</h2>
        <span>
          { translate('ist_report_detailed_results_description') }
        </span>
      </div>
      <div className='marginTopL'>
        { /* MEDICAL HISTORY */ }
        <Subdimension
          id='ist-results-bio-med-hist'
          title={translate('ist_result_subdimension_bio_med_hist')}
          description={translate('ist_result_subdimension_bio_med_hist_description')}
          score={getDimension('bio_med_hist')?.result}
          vulnerable={getDimension('bio_med_hist').classification === 'vulnerable'}
          diff={getDiff('bio_med_hist')}
        >
          <div className={styles.section}>
            <span>{ getDimension('bio_med_hist_stress').name }</span>
            <span>{ getBioMedResult('bio_med_hist_stress') }</span>
          </div>
          <div className={styles.section}>
            <span>{ getDimension('bio_med_hist_traumas').name }</span>
            <span>{ getBioMedResult('bio_med_hist_traumas') }</span>
          </div>
          <div className={styles.section}>
            <span>{ getDimension('bio_med_hist_neg_events').name }</span>
            <span>{ getBioMedResult('bio_med_hist_neg_events') }</span>
          </div>
          { getDimension('bio_med_hist_illnesses') && (
            <div className={styles.section}>
              <span>{ getDimension('bio_med_hist_illnesses').name }</span>
              { renderList('bio_med_hist_illnesses_list') }
            </div>
          ) }
          { getDimension('bio_med_hist_medications') && (
            <div className={styles.section}>
              <span>{ getDimension('bio_med_hist_medications').name }</span>
              <span>{ getBioMedResult('bio_med_hist_medications') }</span>
            </div>
          ) }
          { getDimension('bio_med_alcohol') && (
            <div className={styles.section}>
              <span>{ getDimension('bio_med_alcohol').name }</span>
              <span>{ getBioMedResult('bio_med_alcohol') }</span>
            </div>
          ) }
          { getDimension('bio_med_screen_time') && (
            <div className={styles.section}>
              <span>{ getDimension('bio_med_screen_time').name }</span>
              <span>{ getBioMedResult('bio_med_screen_time') }</span>
            </div>
          ) }
        </Subdimension>

        { /* SYMPTOMS */ }
        <Subdimension
          id='ist-results-symptoms'
          title={translate('ist_result_subdimension_symptoms')}
          description={translate('ist_result_subdimension_symptoms_description')}
          score={getDimension('symptoms')?.result}
          vulnerable={getDimension('symptoms').classification === 'vulnerable'}
          diff={getDiff('symptoms')}
        >
          { renderSymptoms() }
        </Subdimension>

        { /* STRESSORS */ }
        <Subdimension
          id='ist-results-stressors'
          title={translate('ist_result_subdimension_stressors')}
          description={translate('ist_result_subdimension_stressors_description')}
          score={getDimension('stressors')?.result}
          vulnerable={getDimension('stressors').classification === 'vulnerable'}
          diff={getDiff('stressors')}
        >
          <div className={styles.section}>
            <span>
              { translate('ist_ass_dim_stressors_low') }
            </span>
            { renderList('stressors_low') }
          </div>
          <div className={styles.section}>
            <span>
              { translate('ist_ass_dim_stressors_high') }
            </span>
            { renderList('stressors_high') }
          </div>
          { renderBars('stressors') }
        </Subdimension>

        { /* STRESS AMPLIFIERS */ }
        <Subdimension
          id='ist-results-stress-amplifiers'
          title={translate('ist_result_subdimension_stress_amplifier')}
          description={translate('ist_result_subdimension_stress_amplifiers_description')}
          score={getDimension('stress_amplifier')?.result}
          vulnerable={getDimension('stress_amplifier').classification === 'vulnerable'}
          diff={getDiff('stress_amplifier')}
        >
          { renderBars('stress_amplifier') }
        </Subdimension>

        { /* Patient Health Questionnaire */ }
        <Subdimension
          id='ist-results-phq'
          title={translate('ist_result_subdimension_phq')}
          description={translate('ist_result_subdimension_phq_descriptions')}
          score={getDimension('phq')?.result}
          vulnerable={getDimension('phq').classification === 'vulnerable'}
          diff={getDiff('phq')}
        >
          { renderPhqResults() }
          { getDimension('phq_self_harm')?.classification !== 'low_hazard' && (
            <div className={classNames(styles.risk, styles.bars)}>
              <div className={styles.alertWrapper}>
                <div className={styles.alert}>!</div>
              </div>
              <div className={styles.section}>
                { translate('phq_depressive_syndrome_self_harm') }
              </div>
            </div>
          ) }
        </Subdimension>

        { /* CBI */ }
        <Subdimension
          id='ist-results-cbi'
          title={translate('ist_result_subdimension_cbi')}
          description={translate('ist_result_subdimension_cbi_description')}
          score={getDimension('cbi')?.result}
          vulnerable={getDimension('cbi').classification === 'vulnerable'}
          diff={getDiff('cbi')}
        >
          { renderBars('cbi') }
        </Subdimension>

        { /* ERI */ }
        <Subdimension
          id='ist-results-eri'
          title={translate('ist_result_subdimension_eri')}
          description={translate('ist_result_subdimension_eri_description')}
          score={getDimension('eri')?.result}
          vulnerable={getDimension('eri').classification === 'vulnerable'}
          diff={getDiff('eri')}
        >
          <div className={classNames(styles.section, styles.scores, styles.bars)}>
            <div>
              <span>
                { translate('ist_result_subdimension_eri_ratio') }
              </span>
            </div>
            <div>
              { setDecimalSeparator(getDimension('eri_er-ratio').result) }
            </div>
            <div>
              <IstIndicator
                size='S'
                color={getDimension('eri_er-ratio').classification !== 'normal' && 'red'}
              />
            </div>
          </div>
          { renderBars('eri') }
        </Subdimension>
      </div>
    </div>
  );
};

export default DetailedResults;
