// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './IstReport.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentReportNext } from 'ui/molecules/AssessmentReportNext';
// eslint-disable-next-line import/no-cycle
import {
  Introduction,
  PersonalData,
  DetailedResults,
  NextSteps,
} from './sections';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: IstReport
const IstReport = (props) => {
  // PROPS
  const {
    ist,
    user,
    onClose,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // RENDERS
  const introductionPage = () => <Introduction title={ist.title} />;
  const personalDataPage = () => <PersonalData ist={ist} user={user} />;
  const detailedResultsPage = () => <DetailedResults ist={ist} />;
  const nextStepsPage = () => <NextSteps />;

  // PAGES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: IstReport
  return (
    <div className={classNames(styles.istReport)}>
      <AssessmentReportNext
        titleKey={`${translate('ist_assessment_title')} - Report`}
        pages={[
          {
            name: translate('introduction_header_title'),
            content: introductionPage,
          },
          {
            name: translate('ist_report_personal_data_title'),
            content: personalDataPage,
          },
          {
            name: translate('ist_report_detailed_results_title'),
            content: detailedResultsPage,
            subSections: [
              {
                id: 'ist-results-bio-med-hist',
                name: translate('ist_result_subdimension_bio_med_hist'),
              },
              {
                id: 'ist-results-symptoms',
                name: translate('ist_result_subdimension_symptoms'),
              },
              {
                id: 'ist-results-stressors',
                name: translate('ist_result_subdimension_stressors'),
              },
              {
                id: 'ist-results-stress-amplifiers',
                name: translate('ist_result_subdimension_stress_amplifier'),
              },
              {
                id: 'ist-results-phq',
                name: translate('ist_result_subdimension_phq'),
              },
              {
                id: 'ist-results-cbi',
                name: translate('ist_result_subdimension_cbi'),
              },
              {
                id: 'ist-results-eri',
                name: translate('ist_result_subdimension_eri'),
              },
            ],
          },
          {
            name: translate('peer_360_report_next_steps'),
            content: nextStepsPage,
          },
        ]}
        onClose={() => onClose?.()}
      />
    </div>
  );
};

export default IstReport;
